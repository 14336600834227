const rolexTracking = require('./components/rolexTracking');
const scrollAnimate = require('client_core/components/scrollAnimate')
const cookies = require('client_core/components/cookies');

'use strict'

const selectors = {
    displayProductFormTrigger: '.js-product-contact-trigger',
    productForm: '.js-product-formstack',
    rolexFormSelector: '.js-rolex-product-formstack',
    rolexFormSuccessSelector: '.js-rolex-product-formstack-success',
    rolexFormResetSelector: '.js-rolex-product-formstack-reset',
    formStackContainer: '.js-formstack',
    submissionOriginSelector: 'input[label="submissionOrigin"]',
    productIdSelector: 'input[label="Subject"]',
    msgInputSelector: 'textarea.fsFieldLongAnswer'
}

function animateLabels () {
    const formStackContainer = $(selectors.formStackContainer);
    const animatedInputTypes = ['INPUT', 'TEXTAREA', 'SELECT'];
    const styleDataAttribute = 'data-has-value';

    // Add event listener for 'inputs'
    formStackContainer.on('change', function(event) {
        if (animatedInputTypes.includes(event.target.tagName)) {
            const parentLabel = $(event.target).closest('label');
            if (parentLabel.length === 0) {
                return;
            }
            const hasInputValue = !!event.target.value;
            const shouldUpdateStyle = parentLabel.attr(styleDataAttribute) !== hasInputValue;
            if (shouldUpdateStyle) {
                parentLabel.attr(styleDataAttribute, hasInputValue);
            }
        }
    });
}

function setRolexTracking() {
    const successElement = $(selectors.rolexFormSuccessSelector);
    const isVisible = successElement.is(':visible');
    const rlxConsentCookieValue = cookies.methods.getCookie('rlx-consent');

    if (!isVisible || !(rlxConsentCookieValue === 'true')) {
        return;
    }

    rolexTracking.methods.triggerTracking('contactForm')
}

/*
* Helper method to set the value of a Formstack input element and trigger an 'input' event,
* to update the React state of the Formstack form.
* @param {HTMLInputElement} input - The input element to set the value for.
* @param {string} value - The value to set on the input element.
*/
function setFormstackInputValue(input, value) {
    if (!input || !value) {
        return;
    }

    // Create a native browser event
    const nativeInputValueSetter = input.type === 'textarea' ?
        Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set :
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(input, value);

     // Dispatch the event to React's event listeners
    const inputEvent = new Event('input', { bubbles: true });
    input.dispatchEvent(inputEvent);
}

/*
* This function sets the product ID and submission origin value on the product inquiry form input fields.
* These are important values that are used to pass the specific product the user is inquiring about.
* The submission origin value is used to redirect the user back to the product page after successful submission,
* and open the success message modal with correct product id data.
*/
function updateProductInquiryInputs() {
    const form = document.querySelector(selectors.productForm);

    if (!form) {
        return;
    }

    const input = form.querySelector(selectors.productIdSelector);
    const submissionOriginInput = form.querySelector(selectors.submissionOriginSelector);

    // On click of 'Contact Us' button that show modal with form, set the product ID value on the form input
    $(selectors.displayProductFormTrigger).on('click', function () {
        const productId = $(this).data('pid');

        setFormstackInputValue(input, productId);

        // Update the submission origin input value with the product ID
        if  (submissionOriginInput) {
            const submissionOriginValue = submissionOriginInput.getAttribute('value');

            const targetUrlString = submissionOriginValue || window.location.href;

            const successURL = setQueryParameter(targetUrlString, 'productId', productId);

            // Set submission origin URL programmatically on specific hidden formstack input field
            setFormstackInputValue(submissionOriginInput, successURL);
        }
    });
}

/*
* On page load the product ID value is set on the Rolex product inquiry form input field.
* This is important to pass the specific product the user is inquiring about.
* The product id is dynamic and depends on specific PDP page the user is on.
*/
function setProductIdOnRolexForm() {
    const form = document.querySelector(selectors.rolexFormSelector);

    if (!form || !window.rolexProductId) {
        return;
    }

    const input = form.querySelector(selectors.productIdSelector);
    const productId = window.rolexProductId;

    setFormstackInputValue(input, productId);
}

function setMessageOnContactForm() {
    const form = document.querySelector(selectors.rolexFormSelector);

    if (!form || !window.msgDefaultValue) {
        return;
    }

    const input = form.querySelector(selectors.msgInputSelector);
    const msgDefaultValue = window.msgDefaultValue;

    setFormstackInputValue(input, msgDefaultValue);
}

/**
 * Modifies the query parameters of a given URL string by either replacing an existing query key with a new value
 * or appending a new query key and value pair.
 *
 * @param {string} targetUrlString - The URL string to modify.
 * @param {string} queryKey - The key of the query parameter to set or append.
 * @param {string} queryValue - The value of the query parameter to set or append.
 * @returns {string} The modified URL string with the updated query parameters.
 */
function setQueryParameter(targetUrlString, queryKey, queryValue) {
    // Create a URL object with the current page URL
    const targetUrl = new URL(targetUrlString);
    // Check if the queryKey already exists in the URL
    if (targetUrl.searchParams.has(queryKey)) {
        // Replace the existing queryKey with the new queryValue
        targetUrl.searchParams.set(queryKey, queryValue);
    } else {
        // Append the new queryKey and queryValue to the URL
        targetUrl.searchParams.append(queryKey, queryValue);
    }

    // Return the new URL as a string
    return targetUrl.toString();
}

/**
 * Sanitizes the current URL by removing specific query parameters.
 */
function sanitizeCurrentUrl() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('fsSuccessId');
    currentUrl.searchParams.delete('productId');
    window.history.replaceState({}, document.title, currentUrl.toString());
}

/*
* This function sets the successful submission URL a.k.a submission origin value on a hidden input for each Formstack form.
* On submit form will be processed by Formstack and then redirected our API endpoint: redirectform
* Our API endpoint will then redirect to the submission origin URL that we set here programmatically.
* The submission origin URl contains query parameter 'fsSuccessId' with the form ID that triggered submission
* We can use this query param on FE or BE to display a success message of specific form.
*/
function setSuccessfulSubmissionURL() {
    const formContainers = Array.from(document.querySelectorAll(selectors.formStackContainer));

    if (formContainers.length === 0) {
        return;
    }

    sanitizeCurrentUrl();

    // Loop over every formstack form and set the successful submission URL
    formContainers.forEach(container => {
        const submissionInput = container.querySelector(selectors.submissionOriginSelector);
        const formId = container.getAttribute('data-form-id') || '';

        if (!submissionInput || !formId) {
            return;
        }

        const targetUrlString = submissionInput.value || window.location.href;

        let successURL = setQueryParameter(targetUrlString, 'fsSuccessId', formId)

        setFormstackInputValue(submissionInput, successURL);
    });
}

function displayStandardFormSuccessMessage() {
    const form = $(selectors.formStackContainer)
    const message = form.data('success-message') || '';

    $('body').append(
        '<div class="contact-us-signup-message"></div>'
    );

    $('.contact-us-signup-message')
        .append('<div class="contact-us-signup-alert text-center alert-success" role="alert">' + message + '</div>');

    setTimeout(function () {
        $('.contact-us-signup-message').remove();
    }, 5000);
}

function displayProductInquirySuccessMessage(productId) {
    // Finds the trigger element associated with the product ID.
    const triggerElement = $(`.js-product-contact-trigger[data-pid="${productId}"]`);

    if (!triggerElement) {
        return;
    }

    // Hide product inquiry form
    $('.js-pdp-contactus-wrapper').addClass('d-none');
    $('.js-contactus-success').removeClass('d-none');

    // Open modal
    triggerElement.trigger('click');
};

function displayRolexProductSuccessMessage() {
    const rolexForm = $(selectors.rolexFormSelector);
    const rolexFormSuccess = $(selectors.rolexFormSuccessSelector);
    const rolexFormReset = $(selectors.rolexFormResetSelector);

    rolexForm.addClass('d-none');
    rolexFormSuccess.removeClass('d-none');

    scrollAnimate(rolexFormSuccess, 50);
    rolexFormReset.focus();

    rolexFormReset.on('click', function() {
        rolexForm.removeClass('d-none');
        rolexFormSuccess.addClass('d-none');
    });
}

/**
 * Displays a success message based on the 'fsSuccessId' parameter in the URL.
 * Determines the type of success message to display:
 * - 'contactUsFormstack': Standard form success message.
 * - 'contactUsFormstackPDP': Product inquiry success message with the specified product ID.
 * - 'contactUsFormstackRolex': Rolex product success message.
 */
function displaySuccessMessage() {
    const urlParams = new URLSearchParams(window.location.search);
    const successId = urlParams.get('fsSuccessId');
    const productId = urlParams.get('productId');

    if (!successId) {
        return;
    }

    switch (true) {
        case (successId === 'contactUsFormstack'):
            displayStandardFormSuccessMessage();
            break;
        case !!(successId === 'contactUsFormstackPDP' && productId):
            displayProductInquirySuccessMessage(productId);
            break;
       case (successId === 'contactUsFormstackRolex'):
            displayRolexProductSuccessMessage();
            break;
        default:
            break;
    }
}

function hasForms() {
    return !!document.querySelector(selectors.formStackContainer);
}

function init() {
    $(document).ready(() => {

        if (!hasForms()) {
            return;
        }

        // Order of methods is important!
        updateProductInquiryInputs();
        setProductIdOnRolexForm();
        setMessageOnContactForm();
        displaySuccessMessage();
        setRolexTracking();
        setSuccessfulSubmissionURL();
        animateLabels();
    })
}

module.exports = {
    init
}

